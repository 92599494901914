import { useEffect, useCallback, useMemo } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import map from 'lodash/map'
import isNil from 'lodash/isNil'
import last from 'lodash/last'

import {
  slidesSelect,
  slidesReset,
} from './store'
import {
  findKV,
} from './helpers'

const basename = process.env.REACT_APP_BASE_PATH
// https://github.com/ReactTraining/history
const history = createBrowserHistory()

export function Routing(props)
{
  const mapState = useCallback(
    state => ({
      ga: state.project.ga,
      tree: state.tree,
      slide: state.slides['root'],
    }), [])
  const { ga, tree, slide } = useMappedState(mapState)

  const dispatch = useDispatch()
  const dispatchAction = useCallback((action) => dispatch(action), [dispatch])

  // google analytics
  // init
  useEffect(() => {
    if (ga) ReactGA.initialize(ga)
  }, [ga])
  // page view
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (ga) ReactGA.pageview(location.pathname)
    })
    return () => unlisten()
  }, [ga])

  // routes are first level (root) tree slides id/name
  const routes = useMemo(() => map(map(tree['root'].childNodes, (v) => tree[v]), (v) => ({ id: v.id, name: v.element.name })), [tree])
  const getUrl = location => last(location.pathname.split('/'))
  // get route helper
  const getRoute = useCallback((url, key) => {
    if (routes.length === 0) return 'none'
    let route = findKV(routes, { name: url })
    if (isNil(route)) route = findKV(routes, { id: url })
    return !isNil(route) ? route : null
  }, [routes])
  // go to url helper
  const navigate = useCallback(url => {
    const route = getRoute(url)
    if (route === 'none') return
    // if not found, redirect to basename
    if (isNil(route)) {
      history.push(`${basename}`)
      return
    }
    // select current slide according to url
    dispatchAction(slidesSelect('root', route.id))
  }, [getRoute, dispatchAction])

  // go to url at initial page load
  useEffect(() => {
    let url = getUrl(history.location)
    navigate(url)
  }, [navigate])

  // update selection according to url changes
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action !== 'POP') return
      // reset components to initial slide state
      dispatchAction(slidesReset())
      // go to url
      const url = getUrl(location)
      navigate(url)
    })
    return () => unlisten()
  }, [navigate, dispatchAction])

  // update url according to selection changes
  useEffect(() => {
    if (isNil(slide)) return
    // get path
    const route = getRoute(slide)
    if (isNil(route)) return
    const path = !isNil(route.name) ? route.name : route.id
    // skip if already on slide url
    const url = getUrl(history.location)
    if (path === url) return
    history.push(`${basename}${path}`)
  }, [slide, getRoute, routes])

  return null
}
