import React from 'react'
import { useState, useCallback } from 'react'
import { useDispatch, useMappedState } from 'redux-react-hook'
import isNil from 'lodash/isNil'
import difference from 'lodash/difference'

import { Toggle } from './UI'
import { treeSelect } from './store'
import {
  firstcapfull,
  joinSpace
} from './helpers'

// https://github.com/reduxjs/redux/tree/master/examples/tree-view
// https://codesandbox.io/s/9o096z24zr
// tree is used to keep track of components hierarchy breadcrumb
// TODO : avoid rerendering all nodes when selection changes
// TODO : make it agnostic of the master / slide logic ?
export function Tree(props)
{
  const { id, name, breadcrumb } = props

  const mapState = useCallback(state => {
    let node = state.tree[id]
    let from = breadcrumb
    // if node is a component instance, switch to it via its reference
    const isComponent = !isNil(node) && node.element['type'] === 'master' && id !== 'root'
    if (isComponent) {
      const nodeId = node.element['component']
      node = state.tree[nodeId]
      // keep track of instance
      from = [ ...breadcrumb, id ]
    }
    return {
      node: node,
      from: from,
      selected: state.global.selected,
      path: state.global.breadcrumb,
    }
  }, [id, breadcrumb])
  const { node, from, selected, path } = useMappedState(mapState)

  const dispatch = useDispatch()
  const dispatchAction = useCallback((action) => dispatch(action), [dispatch])

  // selection tracks current node / instance
  const select = () => dispatchAction(treeSelect(id, breadcrumb))

  // TODO: spring animation
  const [collapsed, setCollapsed] = useState(id !== 'root')

  if (isNil(node)) return null

  // title
  const setTitle = n => !isNil(n.element['name']) ? n.element['name'] : firstcapfull(n.element.type) + ' ' + name
  const title = setTitle(node)

  // collapse button
  let arrow
  const border = id !== 'root' ? 'border-left' : ''
  if (node.childNodes.length) {
    arrow =
      <div onClick={() => setCollapsed(!collapsed)} className='no-select pointer md md-m-1-t centering inset'>
        {collapsed ? <i className='material-icons'>arrow_right</i> : <i className='material-icons'>arrow_drop_down</i>}
      </div>
  }

  // compare breadcrumb (to prevent double tree selection when inside component)
  const active = id === selected && difference(breadcrumb, path).length === 0

  const button =
    <div className={joinSpace(['row row-left row-nowrap md md-m-h-w', border])}>
      <div style={{width: '1.5em'}}>{arrow}</div>
      <Toggle toggled={active} callback={() => select(id)}><p className={joinSpace(['md center', active ? 'red' : ''])}>{title}</p></Toggle>
    </div>

  return (
    <>
      {button}
      <div className={joinSpace(['md md-m-h-l', collapsed ? 'none' : '', border])}>
        {node.childNodes.map((child, idx) =>
          <Tree key={child} id={child} name={idx+1} breadcrumb={from} />
        )}
      </div>
    </>
  )
}
