import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useMappedState } from 'redux-react-hook'
import map from 'lodash/map'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import last from 'lodash/last'

import Menu from './Menu'
import {
  Toggle,
  Input,
  Info,
  Rule,
} from './UI'
import { grid } from './Scene'
import {
  clamp,
  firstcapfull,
  withorwithout,
  getId,
  findKV,
  filterKV,
  hasKeys,
} from './helpers'
import {
  treeElement,
  treeAdd,
  treeRemove,
  treePaste,
  globalCopy,
  triggersRegister,
  triggersUnregister,
  ancestorMaster,
  ancestorSlide,
  getNestedInstancesFrom,
  slidesSelect,
} from './store'

// elements properties editor & facilities
// prevent forbidden actions from the ui, not from the store (for example by hiding certain buttons)
// set rules at input level as much as possible
// in framer :
// > https://www.framer.com/api/property-controls/
// > https://www.framer.com/api/frame/#performance "try to only animate transform values and opacity, as they are GPU-accelerated"
// TODO : memoize already rendered properties editor ? > https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
// TODO : manage properties from block components, with hooks ? > https://reactjs.org/docs/hooks-custom.html
// TODO : select inputs, popup window, ...
// TODO : shadow, blueprint, patterns, unlink symbol, edit all, copy properties, ...
// TODO : unlink > apply a new ref to the element in order to unlink it from animation
// TODO : consider applying changes to component node directly from here (replace [selected] instance by referenced component) ?
export function Controls(props)
{
  const { notify } = props

  const mapState = useCallback(state => ({
    assets: state.library.assets,
    clipboard: state.global.clipboard,
    selected: state.global.selected,
    breadcrumb: state.global.breadcrumb,
    tree: state.tree,
    triggers: state.triggers,
    slides: state.initial,
  }), [])
  const { assets, clipboard, selected, breadcrumb, tree, triggers, slides } = useMappedState(mapState)

  const dispatch = useDispatch()
  const dispatchAction = useCallback((action) => dispatch(action), [dispatch])

  const [lists, setLists] = useState([])
  // keep track of menu path (vertical menu callback)
  const [path, setPath] = useState(['root'])
  const [menu, setMenu] = useState('root')
  // buffer states (for operations like setting up triggers, sampling tree nodes, ...)
  // different keys must be used (for trigger types, ...) in order to avoid overrides to occur across menu panels
  const [buffer, setBuffer] = useState({})

  // path update
  useEffect(() => {
    const element = tree[selected].element
    const type = firstcapfull(element.type)
    const menupath = `${type}${path.length > 1 ? ' / ' + path.slice(1).join(' / ') : ''}`
    notify(menupath)
  }, [path, notify, tree, selected])

  // reset buffer if menu has changed
  useEffect(() => {
    const m = last(path).toLowerCase()
    if (menu !== m) {
      if (!isEmpty(buffer)) setBuffer({})
      // set current menu
      setMenu(m)
    }
  }, [path, menu, buffer])

  // generate lists menu
  // TODO : only on change: selection, buffer
  useEffect(() => {

    // selected node infos
    const e = tree[selected].element
    const type = e.type
    const isMaster = type === 'master'
    const isSlide = type === 'slide'
    // TODO : component overrides
    const isComponent = selected !== 'root' && isMaster
    const element = isComponent ? tree[e.component].element : e

    // helpers
    const findTrigger = kvs => findKV(triggers, kvs)
    const filterTrigger = kvs => filterKV(triggers, kvs)
    // pasting validation
    const canPaste = () => {
      if (clipboard.length !== 0) {
        const c = clipboard.slice()
        const f = c[0]
        // prevent pasting components inside themselves to avoid cyclic dependencies
        // ensure that target master for pasting doesn't include clipboard instances
        const master = ancestorMaster(tree, selected, true)
        if (master !== 'root') {
          const target = tree[master].element.component
          const components = map(getNestedInstancesFrom(tree, f), v => v.element.component)
          if (includes(components, target)) return false
        }
        // don't show paste button if clipboard parent node has not the right type
        const t = f.element.type
        if ((isMaster && t === 'slide') || (!isMaster && t !== 'slide')) return true
      }
      // nothing to paste
      return false
    }
    // comparing buffers against selected / breadcrumb
    // check for master ancestry (breadcrumb is used to access instances)
    const sameMaster = (k, is = true) => (buffer[k] ? (last(breadcrumb) === last(buffer[k].breadcrumb)) === is : true)
    // check for slide ancestry (used to prevent targeting the same slide twice)
    const sameSlide = (id1, id2) => ancestorSlide(tree, id1) === ancestorSlide(tree, id2)
    const notSameSlide = k => (buffer[k] ? !sameSlide(selected, buffer[k].id) : true)
    const isParent = k => (buffer[k] ? selected === last(buffer[k].breadcrumb) : true)
    const hasMaster = k => (buffer[k] ? last(breadcrumb) === buffer[k].id : true)
    // main actions
    // TODO : spring can't animate from null to something : double dispatch default to something when null ?
    const dispatchEdit = (n, p, v) => dispatchAction(treeElement(n, p, v))
    const dispatchAdd = e => dispatchAction(treeAdd(e))
    const dispatchRemove = () => dispatchAction(treeRemove())
    const dispatchPaste = () => dispatchAction(treePaste())
    const dispatchCopy = () => dispatchAction(globalCopy(tree, selected))
    const dispatchRegister = (n, i, t, g) => dispatchAction(triggersRegister(n, i, t, g))
    const dispatchUnregister = f => dispatchAction(triggersUnregister(f))
    // numbers
    const posneg = (neg, val) => neg ? -(val) : val
    const increment = (t, min, max, val, start) => {
      const actual = element[t]
      const from = isNil(actual) ? start : actual
      const to = clamp((from + val).toFixed(2), min, max)
      dispatchEdit(selected, t, to)
    }
    // text inputs (currying / partial application !)
    const input = cb => e => {
      const v = e.target.value
      if (v.length <= 16) cb(v)
    }
    const inputName = input(v => {
      // set name if non empty and contains at least a non-space character
      // https://stackoverflow.com/a/2031143/7662622
      dispatchEdit(selected, 'name', v.length > 0 && (/\S/.test(v)) ? v : null)
    })
    const inputMessage = input(v => {
      setBuffer(withorwithout(buffer, 'input', v))
    })
    const toggleBuffer = (k, v) => setBuffer(withorwithout(buffer, k, !isNil(buffer[k]) && buffer[k] === v ? null : v))
    // check/set current master initial slide
    const isInitial = () => !isNil(slides[last(breadcrumb)]) && slides[last(breadcrumb)] === selected
    const setInitial = () => dispatchAction(slidesSelect(last(breadcrumb), isInitial() ? null : selected, 'initial'))

    // ui items
    const item = (t, o) => {
      if (t === 'idle') return (
        <Toggle margin disabled>
          <p className='center'>{o.label}</p>
        </Toggle>
      )
      if (t === 'text') return (
        <Toggle margin callback={o.cb} toggled={isNil(o.active) ? false : o.active}>
          <div className='center'>{o.label}</div>
        </Toggle>
      )
      // flex fix: https://stackoverflow.com/a/8554054/7662622
      if (t === 'image') return (
        <Toggle margin callback={o.cb}>
          <div className='contain flex' style={{ width: '11em', height: '11em', backgroundImage: 'url(' + o.url + ')' }}/>
        </Toggle>
      )
      if (t === 'color') return (
        <Toggle margin callback={o.cb} toggled={isNil(o.active) ? false : o.active}>
          <div style={{ width: '5em', height: '5em', backgroundColor: 'rgb(' + o.color + ')' }}/>
        </Toggle>
      )
      if (t === 'font') return (
        <Toggle margin callback={o.cb} toggled={isNil(o.active) ? false : o.active}>
          <p className='center' style={{fontFamily: o.font}}>{o.font}</p>
        </Toggle>
      )
      if (t === 'info') return (
        <Info>
          {isNil(o.value) ? '-' : o.value}
        </Info>
      )
      if (t === 'input') return (
        <Input margin value={isNil(o.value) ? '' : o.value} callback={o.cb} />
      )
      if (t === 'node') {
        const node = tree[o.id]
        return (
          <Toggle margin toggled>
            <p className='md center'>{node.element.name ? node.element.name : firstcapfull(node.element.type)}</p>
          </Toggle>
        )
      }
      if (t === 'toggle') {
        return (
          <Toggle margin callback={o.cb} toggled={o.active}>
            <p className='md center'>{o.value}</p>
          </Toggle>
        )
      }
      if (t === 'rule') return <Rule/>
    }
    const leaf = (t, o) => ({ type: 'leaf', content: item(t, o) })
    const node = (t, n) => ({ type: 'node', content: t, node: n })

    // leafs
    // TODO : toogle / boolean
    // TODO : generalize for multiple trigger types
    // TODO : restrict target to slides inside the same master as the source ?
    // TODO : array select helper
    const master = leaf('text', { label: 'Master', cb: () => dispatchAdd({ type: 'master' }) })
    const slide = leaf('text', { label: 'Slide', cb: () => dispatchAdd({ type: 'slide' }) })
    const div = leaf('text', { label: 'Div', cb: () => dispatchAdd({ type: 'div' }) })
    const remove = leaf('text', { label: 'Remove', cb: () => dispatchRemove() })
    const copy = leaf('text', { label: 'Copy', cb: () => dispatchCopy() })
    const paste = leaf('text', { label: 'Paste', cb: () => dispatchPaste() })
    const none = (t) => leaf('text', { label: 'None', cb: () => dispatchEdit(selected, t, null), active: isNil(element[t]) })
    const cancel = leaf('text', { label: 'Cancel', cb: () => setBuffer({}) })
    const confirm = (c) => leaf('text', { label: 'Confirm', cb: c })
    const nodeRef = (n) => leaf('node', { id: n })
    // currying / partial application
    const showorpick = (t, o) => {
      return (k, c) => {
        if (buffer[k]) return leaf(t, buffer[k])
        if (c) return leaf('text', { label: '-', cb: () => setBuffer(withorwithout(buffer, k, o)) })
        return leaf('idle', { label: '-' })
      }
    }
    const pickNode = showorpick('node', { breadcrumb: breadcrumb, id: selected })
    // const pickInfo = showorpick('info', { value: 'This' })
    const initial = leaf('toggle', { value: 'Initial', active: isInitial(), cb: () => setInitial() })

    const colors = map(assets.colors, (v, i) =>
      leaf('color', { color: v['color'], cb: () => dispatchEdit(selected, 'color', assets.colors[i]['id']), active: element['color'] === assets.colors[i]['id'] })
    )
    const fonts = map(assets.fonts, (v, i) =>
      leaf('font', { font: v['name'], cb: () => dispatchEdit(selected, 'font', assets.fonts[i]['id']), active: element['font'] === assets.fonts[i]['id'] })
    )
    const texts = map(assets.texts, (v, i) =>
      leaf('text', { label: v['excerpt'], cb: () => dispatchAdd({ type: 'text', id: assets.texts[i]['id']}) })
    )
    const images = map(assets.images, (v, i) =>
      leaf('image', { url: v['url'], cb: () => dispatchAdd({ type: 'image', id: assets.images[i]['id']}) })
    )
    const moreorless = (t, cb) => [
      leaf('info', { value: element[t] }),
      none(t),
      leaf('text', { label: '+', cb: () => cb(false) }),
      leaf('text', { label: '-', cb: () => cb(true) }),
    ]
    const toggle = (n) => [
      leaf('text', { label: 'Off', cb: () => dispatchEdit(selected, n, 'off'), active: element[n] === 'off' }),
      leaf('text', { label: 'On', cb: () => dispatchEdit(selected, n, 'on'), active: element[n] === 'on' })
    ]
    // triggers panels
    // display node pickers, retrieve existing triggers, ...
    // TODO : comments
    const getTrigger = (t) => {
      let n = []

      if (t === 'click') {
        const source = findTrigger({ node: selected, name: 'clickS' })
        const noTrigger = isNil(source)
        // no click > display selector
        if (noTrigger) {
          const cf = pickNode('cf', !isSlide && notSameSlide('ct') && sameMaster('ct'))
          const ct = pickNode('ct', isSlide && notSameSlide('cf') && sameMaster('cf'))
          n = [
            leaf('info', { value: 'Source' }),
            cf,
            leaf('info', { value: 'Target' }),
            ct,
            !isEmpty(buffer) && cancel,
            hasKeys(buffer, ['cf', 'ct']) && confirm(() => {
              const triggerId = getId()
              dispatchRegister(buffer['cf'].id, triggerId, 'clickS')
              dispatchRegister(buffer['ct'].id, triggerId, 'clickT')
              setBuffer({})
            }),
            leaf('rule'),
          ]
        // click exists > display editing
        } else {
          const target = findTrigger({ trigger: source.trigger, name: 'clickT' })
          n = [
            leaf('info', { value: 'Target' }),
            nodeRef(target.node),
            leaf('text', { label: 'Remove', cb: () => dispatchUnregister({ trigger: source.trigger }) }),
            leaf('rule'),
          ]
        }
      }

      if (t === 'auto') {
        const txt = buffer['input']
        const empty = isNil(txt) || txt.length === 0
        // publish list
        const triggers = filterTrigger({ node: selected, name: 'auto' })
        const autos = map(triggers, v => leaf('toggle', {
          value: v.message,
          active: v.trigger === buffer.selected,
          cb: () => toggleBuffer('selected', v.trigger)
        }))
        const list = triggers.length ? [ leaf('rule'), ...autos ] : []
        // validation checks
        const exists = findKV(triggers, { message: txt })
        const canConfirm = !empty && !exists
        // selection editing
        const editing = !isNil(buffer.selected) && findKV(triggers, { trigger: buffer.selected })
        const unregister = () => dispatchUnregister({ trigger: buffer.selected })
        const edit =  editing ? [
          leaf('rule'),
          leaf('text', { label: 'Remove', cb: () => unregister() })
        ] : []
        n = [
          leaf('info', { value: 'Message' }),
          leaf('input', { value: txt, cb: inputMessage }),
          canConfirm && confirm(() => {
            dispatchRegister(selected, getId(), 'auto', { message: txt })
            setBuffer({})
          }),
          ...list,
          ...edit,
        ]
      }

      if (t === 'echo') {
        // inputs
        const txt = buffer['input']
        const empty = isNil(txt) || txt.length === 0
        const hasFrom = hasKeys(buffer, ['cf'])
        const hasTo = hasKeys(buffer, ['ct'])
        // if source is a slide, it must be different and from the same parent as target
        // if source is a master, it has to be the target master ancestor
        // target must be a different slide from source, or a descendant from source it this is a master
        // const cf = pickNode('cf', isSlide && notSameSlide('ct') && sameMaster('ct'))
        // const ct = pickNode('ct', isSlide && notSameSlide('cf') && sameMaster('cf'))
        const cf = pickNode('cf', (isSlide && notSameSlide('ct') && sameMaster('ct')) || (isMaster && isParent('ct')))
        const source = hasFrom && tree[buffer['cf'].id]
        const masterSource = source && source.element.type === 'master'
        const ct = pickNode('ct', isSlide && notSameSlide('cf') && (masterSource ? hasMaster('cf') : sameMaster('cf')))
        // subscribe list
        const triggers = filterTrigger({ node: selected, name: 'echoS' })
        const echos = map(triggers, v => leaf('toggle', {
          value: v.message,
          active: v.trigger === buffer.selected,
          cb: () => toggleBuffer('selected', v.trigger)
        }))
        const list = triggers.length ? [ leaf('rule'), ...echos ] : []
        // validation checks
        // check if message exists already among echo triggers owned by the selected source
        const triggersS = hasFrom ? filterTrigger({ node: buffer['cf'].id, name: 'echoS' }) : []
        const exists = findKV(triggersS, { message: txt })
        const canCancel = !empty || hasFrom || hasTo
        const canConfirm = !empty && !exists && hasFrom && hasTo
        // selection editing
        const editing = !isNil(buffer.selected) && findKV(triggers, { trigger: buffer.selected })
        const target = !isNil(buffer.selected) && findTrigger({ trigger: buffer.selected, name: 'echoT' })
        const unregister = () => dispatchUnregister({ trigger: buffer.selected })
        const edit = editing ? [
          leaf('rule'),
          leaf('info', { value: 'Target' }),
          nodeRef(target.node),
          leaf('text', { label: 'Remove', cb: () => unregister() })
        ] : []
        n = [
          leaf('info', { value: 'Message' }),
          leaf('input', { value: txt, cb: inputMessage }),
          leaf('info', { value: 'Source' }),
          cf,
          leaf('info', { value: 'Target' }),
          ct,
          (canCancel || canConfirm) && leaf('rule'),
          canCancel && cancel,
          canConfirm && confirm(() => {
            const triggerId = getId()
            dispatchRegister(buffer['cf'].id, triggerId, 'echoS', { message: txt })
            dispatchRegister(buffer['ct'].id, triggerId, 'echoT')
            setBuffer({})
          }),
          ...list,
          ...edit,
        ]
      }

      return n
    }

    // nodes
    const color = node('Color', [none('color'), ...colors])
    const font = node('Font', [none('font'), ...fonts])
    const text = node('Text', texts)
    const image = node('Image', images)
    const opacity = node('Opacity', moreorless('opacity', (neg) => increment('opacity', 0, 1, posneg(neg, .1), 1)))
    const scale = node('Scale', moreorless('scale', (neg) => increment('scale', -10, 10, posneg(neg, .1), 1)))
    const translateX = node('Translate X', moreorless('translateX', (neg) => increment('translateX', -grid.columns, grid.columns, posneg(neg, 1), 0)))
    const translateY = node('Translate Y', moreorless('translateY', (neg) => increment('translateY', -grid.rows, grid.rows, posneg(neg, 1), 0)))
    const translateZ = node('Translate Z', moreorless('translateZ', (neg) => increment('translateZ', -grid.rows, grid.rows, posneg(neg, 1), 0)))
    const rotateX = node('Rotate X', moreorless('rotateX', (neg) => increment('rotateX', -360, 360, posneg(neg, 15), 0)))
    const rotateY = node('Rotate Y', moreorless('rotateY', (neg) => increment('rotateY', -360, 360, posneg(neg, 15), 0)))
    const rotateZ = node('Rotate Z', moreorless('rotateZ', (neg) => increment('rotateZ', -360, 360, posneg(neg, 15), 0)))
    const width = node('Width', moreorless('width', (neg) => increment('width', 0, 1000, posneg(neg, 1), 0)))
    const height = node('Height', moreorless('height', (neg) => increment('height', 0, 1000, posneg(neg, 1), 0)))
    const top = node('Top', moreorless('top', (neg) => increment('top', -grid.rows, grid.rows, posneg(neg, 1), 0)))
    const left = node('Left', moreorless('left', (neg) => increment('left', -grid.columns, grid.columns, posneg(neg, 1), 0)))
    const size = node('Size', moreorless('size', (neg) => increment('size', 0, 1600, posneg(neg, 40), 40)))
    const shadow = node('Shadow', [none('shadow'), ...toggle('shadow')])
    const rename = node('Rename', [leaf('input', { value: element['name'], cb: inputName })])
    const click = node('Click', getTrigger('click'))
    const auto = node('Auto', getTrigger('auto'))
    const echo = node('Echo', getTrigger('echo'))

    // menus
    let lists = []

    // master
    // root node cannot be edited nor removed
    if (type === 'master') {
      const isRoot = selected === 'root'
      const properties = [
        scale,
        translateX,
        translateY,
        translateZ,
        rotateX,
        rotateY,
        rotateZ,
        width,
        height,
        top,
        left,
      ]
      const elements = [
        slide,
      ]
      const edit = node('Edit', properties)
      const add = node('Add', elements)
      lists = [
        !isRoot && edit,
        add,
        !isRoot && copy,
        canPaste() && paste,
        !isRoot && remove,
        !isRoot && rename,
        node('Trigger', [ echo ]),
      ]
    }

    // slide
    if (type === 'slide') {
      const properties = [
        color,
      ]
      const elements = [
        master,
        div,
        text,
        image,
      ]
      const edit = node('Edit', properties)
      const add = node('Add', elements)
      lists = [
        initial,
        edit,
        add,
        copy,
        canPaste() && paste,
        remove,
        rename,
        node('Trigger', [ click, auto, echo ]),
      ]
    }

    // div
    if (type === 'div') {
      const properties = [
        opacity,
        scale,
        translateX,
        translateY,
        translateZ,
        rotateX,
        rotateY,
        rotateZ,
        width,
        height,
        top,
        left,
        shadow,
        color,
      ]
      const elements = [
        master,
        div,
        text,
        image,
      ]
      const edit = node('Edit', properties)
      const add = node('Add', elements)
      lists = [
        edit,
        add,
        copy,
        canPaste() && paste,
        remove,
        node('Trigger', [ click ]),
      ]
    }

    // text
    if (type === 'text') {
      const properties = [
        opacity,
        scale,
        translateX,
        translateY,
        translateZ,
        rotateX,
        rotateY,
        rotateZ,
        width,
        height,
        top,
        left,
        shadow,
        size,
        color,
        font,
      ]
      const edit = node('Edit', properties)
      lists = [
        edit,
        copy,
        canPaste() && paste,
        remove,
        node('Trigger', [ click ]),
      ]
    }

    // image
    if (type === 'image') {
      const properties = [
        opacity,
        scale,
        translateX,
        translateY,
        translateZ,
        rotateX,
        rotateY,
        rotateZ,
        width,
        height,
        top,
        left,
        shadow,
      ]
      const edit = node('Edit', properties)
      lists = [
        edit,
        copy,
        canPaste() && paste,
        remove,
        node('Trigger', [ click ]),
      ]
    }

    setLists(lists)
  }, [assets, tree, selected, breadcrumb, clipboard, dispatchAction, buffer, triggers, slides])

  // pass path to maintain the same path between different nodes types
  // or > use a key in order to reset component state (path) when switching type ?
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#preferred-solutions
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
  return <Menu list={lists} path={path} callback={setPath}/>
}
