import React from 'react'
import { useEffect, useState } from 'react'
import find from 'lodash/find'
import isNil from 'lodash/isNil'

import { Toggle } from './UI'

// nested vertical menus navigation
// TODO : flatten data representation, refactor with hooks, no callback ?
// TODO : Make it pure, save breadcrumb with the node ? Component rendering itself recursively ?
// TODO : Pass breadcrumb and go to path if it exists, else go to root
function Menu(props)
{
  const { list, path, callback } = props

  const [menus, setMenus] = useState(null)
  const [breadcrumb, setBreadcrumb] = useState(path)

  // init menus
  useEffect(() => {
    // utility function to flatten menu/breadcrumb datas: [menu 1, menu 2, ...]
    // TODO: Avoid recomputing makeMenus() every render/select
    const makeMenus = (name, list, key) =>
    {
      key = isNil(key) ? ['root'] : key
      let menus = [{name: name, list: list, key: key}]
      list.forEach((item, i) => {
        if (item && item.type === 'node') menus = [...menus, ...makeMenus(item.content, item.node, [...key, item.content])]
      })
      return menus
    }
    const m = makeMenus('root', list)
    setMenus(m)

    // check if path exists and reset to root if not
    const menu = getMenu(m, path)
    if (isNil(menu)) setBreadcrumb(['root'])
  }, [list, path])

  const getMenu = (a, v) => find(a, i => i.key.toString() === v.toString())

  // callback
  useEffect(() => {
    if (menus && callback) callback(breadcrumb)
  }, [breadcrumb, menus, callback])

  const select = (index) =>
  {
    // go back
    if (index === -1) {
      setBreadcrumb(breadcrumb.slice(0, -1))
    // go forward
    } else {
      const menu = getMenu(menus, breadcrumb)
      const item = menu.list[index]
      if (item.type === 'node') setBreadcrumb([...breadcrumb, item.content])
    }
  }

  if (!menus) return null

  let jsx = []
  for (let i = 0; i < menus.length; i++) {
    const key = menus[i].key
    const list = menus[i].list
    const current = key.toString() === breadcrumb.toString()

    // only current level has callback action
    const callback = current && { callback: select }

    // position
    // TODO: spring animation
    const style = {
      top: '0',
      padding: '50vh 0',
      right: '0',
      display: current ? '' : 'none',
    }

    // back button, except for the main level
    const back = (i !== 0) && <Item key={-1} index={-1} type='node' content='&#x2190;' {...callback} />
    const menu =
      <ul key={key} className='absolute fill-h scroll-y' style={style}>
        {back}
        {list.map((item, j) =>
          item && <Item key={j} index={j} type={item.type} content={item.content} {...callback} />
        )}
      </ul>

    jsx.push(menu)
  }

  return jsx
}

export default Menu

//

function Item(props)
{
  const { type, content, index, callback } = props

  const trigger = () => {
    if (callback) callback(index)
  }

  let jsx = content

  if (type === 'node') {
    jsx =
      <Toggle margin callback={trigger}>
        <p className='center'>{content}</p>
      </Toggle>
  }

  return (
    <li>{jsx}</li>
  )
}
