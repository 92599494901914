import axios from 'axios'

export function performRequest(method, url, datas)
{
  const body = (method === 'get') ? 'params' : 'data'

  const config = {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    baseURL: process.env.REACT_APP_API_URL,
    url: url,
    withCredentials: true,
    [body]: datas
  }

  return axios.request(config)
}
