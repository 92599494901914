import React from 'react'
import { useCallback } from 'react'
import { useSpring, animated, config } from 'react-spring'
import { useDispatch, useMappedState } from 'redux-react-hook'
import map from 'lodash/map'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'

import { grid } from './Scene'
import {
  ancestorSlide,
  triggerPublish,
} from './store'
import {
  joinSpace,
  mergeIf,
  getAsset,
  filterKV,
  findKV,
  usePrevious,
} from './helpers'

// master component renders with its current state / slide
// see https://principleformac.com/docs.html#events
// see https://principleformac.com/docs.html#components
// some property values can be inexistent in state but must always be present for animation, hence default values are used
// TODO: default values: gather them somewhere, move it at object factory, helper, ... ?
// TODO : when setting a prop to null and spring animation is not finished, default value is not taken : spring stop / finished fn ?
// TODO : spring can't animate from null to something > refactor css / spring animation handling, double dispatch, usePrevious ?
// TODO : scale3d()
// TODO : how to animate background-color from null to something ?
// TODO : master animated properties
export function Master(props)
{
  const { target, parent, editing, selected } = props

  const mapState = useCallback(state => ({
    assets: state.library.assets,
    tree: state.tree,
    triggers: state.triggers,
    slide: state.slides[target.id],
    initial: state.initial[parent],
  }), [target, parent])
  const { assets, tree, triggers, slide, initial } = useMappedState(mapState)

  const dispatch = useDispatch()
  const dispatchAction = useCallback((action) => dispatch(action), [dispatch])

  const isRoot = target.id === 'root'

  // animated
  let animate = { config: config.default }
  // get width / height from master parent so all slides under the same master will have the same size
  animate = mergeIf(!isNil(target.element['width']), animate, { width: (target.element['width'] * grid.offset) + 'px' })
  animate = mergeIf(!isNil(target.element['height']), animate, { height: (target.element['height'] * grid.offset) + 'px' })

  // ensure that there is something to animate from root master
  if (isRoot) animate.from = { width: '0px', height: '0px' }

  // defaults
  let defaults = {}
  defaults = mergeIf(isNil(animate['width']), defaults, { width: '100%' })
  defaults = mergeIf(isNil(animate['height']), defaults, { height: '100%' })

  // background color (default)
  if (isRoot) document.body.style.backgroundColor = editing ? 'rgb(54,54,69)' : 'rgb(255,255,255)'

  // slide
  // slide chasing a target state, animations
  // TODO: ref on slide to avoid animating backgroundColor ?

  // master current slide logic
  // TODO: what slide logic ?
  // TODO: targets
  // TODO: put that further up and pass it via props ?
  let currentSlide
  // edit mode : (does not know about masters states)
  if (editing) {
    // if master includes the selection : target selected slide, else target first slide
    const ancestor = ancestorSlide(tree, selected)
    const includesSelected = includes(target.childNodes, ancestor)
    currentSlide = includesSelected ? tree[ancestor] : !isNil(initial) ? tree[initial] : tree[target.childNodes[0]]
  // view mode : (does not know about selected node) :
  } else {
    // if there is a master slide state select it, else target first slide
    currentSlide = !isNil(slide) ? tree[slide] : !isNil(initial) ? tree[initial] : tree[target.childNodes[0]]
  }
  // save previous slide for later
  const previousSlide = usePrevious(currentSlide)

  // hierarchy
  let children
  if (!isNil(currentSlide)) {

    // auto trigger
    // only if slide state is not initial > it has a temporal state which overrides initial:
    // prevent from calling auto at initial render or at slides state reset to initial (in routing)
    // only if slide has changed:
    // to prevent an eventual infinite loop (occurs when several slides in the same master listen to the same messages and target each other)
    if (!editing && !isNil(slide) && currentSlide !== previousSlide) {
      const autoT = filterKV(triggers, { node: currentSlide.id, name: 'auto' })
      if (autoT.length) dispatchAction(triggerPublish('auto', { source: currentSlide.id, triggers: autoT }))
    }

    // animated
    // get background color from target slide
    const color = getAsset(assets.colors, currentSlide.element['color'])
    if (!isNil(color['color'])) {
      // if at root > full body background, else > component slide only
      if (isRoot) document.body.style.backgroundColor = `rgb(${color['color']})`
      else defaults = mergeIf(true, defaults, { backgroundColor: `rgb(${color['color']})` })
    }

    children =
      <Blocks
        tree={tree}
        childNodes={currentSlide.childNodes}
        editing={editing}
        selected={selected}
      />
  }

  // inline styles
  const inline = { ...useSpring(animate), ...defaults }

  // display outline for non root masters
  const focus = target.id === selected || (!isNil(currentSlide) && currentSlide.id === selected)
  const outline = editing && !isRoot && focus ? 'outline' : ''

  return (
    <animated.div style={inline} className={joinSpace(['relative centering anim-back', outline])}>
      {children}
    </animated.div>
  )
}

// BLOCKS
// children nodes as components
function Blocks(props)
{
  const {
    childNodes,
    editing,
    selected,
    tree,
  } = props

  const mapState = useCallback(state => ({
    assets: state.library.assets,
    triggers: state.triggers,
  }), [])
  const { assets, triggers } = useMappedState(mapState)

  const dispatch = useDispatch()
  const dispatchAction = useCallback((action) => dispatch(action), [dispatch])

  return map(childNodes, (nodeId, i) => {
    let node = tree[nodeId]
    let element = node.element
    const type = element.type
    const trigger = filterKV(triggers, { node: nodeId })
    const ref = element.ref
    // if node is a component instance, switch to it via its reference
    // TODO : component overrides
    const isComponent = type === 'master'
    if (isComponent) {
      node = tree[element['component']]
      element = node.element
    }

    // define child blocks
    // TODO : function with return
    let block = null
    if (type === 'master') block =
      <Master target={node} parent={nodeId} editing={editing} selected={selected}/>
    if (type === 'div') block =
      <BlockDiv element={element} assets={assets}>
        <Blocks
          tree={tree}
          childNodes={node.childNodes}
          editing={editing}
          selected={selected}
        />
      </BlockDiv>
    if (type === 'text') block =
      <BlockText element={element} assets={assets}/>
    if (type === 'image') block =
      <BlockImage element={element} assets={assets} draggable={!editing}/>
    return (
      <Block
        key={ref}
        id={nodeId}
        editing={editing}
        selected={selected}
        element={element}
        trigger={trigger}
        dispatch={dispatchAction}
      >
        {block}
      </Block>
    )
  })
}

// MAIN BLOCK
// container block for every block
// TODO : prevent unwanted rerender/repaint
// > avoid changing props (id, trigger, ...), memo, extract ref & type so accessible out of element that may change ?
function Block(props)
{
  const {
    id,
    editing,
    selected,
    element,
    trigger,
    dispatch,
  } = props

  // click trigger
  // https://principleformac.com/docs.html#touch-routing
  // TODO : mobile touch ?
  // TODO : nested touch routing ?
  // TODO : fix interrupting animation causing repaint
  const clickT = findKV(trigger, { name: 'clickS' })
  const clickable = !isNil(clickT) && !editing
  const click = clickable && { onClick: () => dispatch(triggerPublish('clickS', { trigger: clickT })) }
  const pointer = clickable ? 'pointer' : ''
  // prevent non clickable elements from hiding clickable ones
  // https://github.com/impress/impress.js/issues/128#issuecomment-4862215
  const events = clickable ? 'events' : 'noevents'

  // animated
  let animate = { config: config.default }
  animate = mergeIf(!isNil(element['width']), animate, { width: element['width'] * grid.offset + 'px' })
  animate = mergeIf(!isNil(element['height']), animate, { height: element['height'] * grid.offset + 'px' })
  animate = mergeIf(!isNil(element['top']), animate, { top: element['top'] * grid.offset + 'px' })
  animate = mergeIf(!isNil(element['left']), animate, { left: element['left'] * grid.offset + 'px' })
  animate = mergeIf(!isNil(element['opacity']), animate, { opacity:  element['opacity'] })

  // transforms
  // https://css-tricks.com/things-watch-working-css-3d/
  // https://developer.mozilla.org/en-US/docs/Web/CSS/transform-style
  // TODO: preserve-3d (property is not inherited) current > descendants
  let transforms = {}
  const tx = element['translateX']
  const ty = element['translateY']
  const tz = element['translateZ']
  // TODO : translate3d, css transform helpers ?
  let xyz = (!isNil(tx) || !isNil(ty) || !isNil(tz))
    ? `${!isNil(tx)?tx*grid.offset+'px':'0px'},${!isNil(ty)?ty*grid.offset+'px':'0px'},${!isNil(tz)?tz*grid.offset+'px':'0px'}`
    : null
  transforms = mergeIf(!isNil(xyz), transforms, { translate3d: xyz })
  transforms = mergeIf(!isNil(element['rotateX']), transforms, { rotateX: element['rotateX'] })
  transforms = mergeIf(!isNil(element['rotateY']), transforms, { rotateY: element['rotateY'] })
  transforms = mergeIf(!isNil(element['rotateZ']), transforms, { rotateZ: element['rotateZ'] })
  transforms = mergeIf(!isNil(element['scale']), transforms, { scale: element['scale'] })
  const t3d = !isNil(transforms.translate3d) ? transforms.translate3d : '0px,0px,0px'
  const rx = !isNil(transforms.rotateX) ? transforms.rotateX : 0
  const ry = !isNil(transforms.rotateY) ? transforms.rotateY : 0
  const rz = !isNil(transforms.rotateZ) ? transforms.rotateZ : 0
  const sc = !isNil(transforms.scale) ? transforms.scale : 1
  // perspective(${grid.height/2}px)
  const transform = `translate3d(${t3d}) rotateX(${rx}deg) rotateY(${ry}deg) rotateZ(${rz}deg) scale(${sc})`
  animate = mergeIf(!isEmpty(transforms), animate, { transform: transform })
  animate = mergeIf(!isEmpty(transforms), animate, { WebkitTransform: transform })
  const transformed = !isEmpty(transforms) ? 'transform' : ''

  // defaults
  // TODO : z-index ?
  // let defaults = { zIndex: zIndex }
  let defaults = {}
  // master, div : container default size is 100%
  // others : container default size fits their contents
  const size = element.type === 'master' || element.type === 'div' ? '100%' : null
  defaults = mergeIf(isNil(animate['width']), defaults, { width: size })
  defaults = mergeIf(isNil(animate['height']), defaults, { height: size })
  defaults = mergeIf(isNil(animate['top']), defaults, { top: null })
  defaults = mergeIf(isNil(animate['left']), defaults, { left: null })
  defaults = mergeIf(isNil(animate['opacity']), defaults, { opacity: null })
  defaults = mergeIf(isEmpty(transforms), defaults, { transform: null })
  defaults = mergeIf(isEmpty(transforms), defaults, { WebkitTransform: null })

  // inline styles
  const inline = { ...useSpring(animate), ...defaults }

  // if selection highlight it
  const outline = editing && id === selected ? 'outline' : ''

  // console.log(element.type)

  return (
    <animated.div
      className={joinSpace(['absolute centering origin-center', transformed, outline, pointer, events])}
      style={inline}
      {...click}
    >
      {props.children}
    </animated.div>
  )
}

// DIV
function BlockDiv(props)
{
  const { element, assets } = props

  // assets
  const color = getAsset(assets.colors, element['color'])

  // animated
  let animate = { config: config.default }
  animate = mergeIf(!isNil(element['opacity']), animate, { opacity:  element['opacity'] })
  animate = mergeIf(color, animate, { backgroundColor:  `rgb(${color['color']})` })
  animate.from = { backgroundColor: 'rgba(255,255,255,0)' }

  // defaults
  let defaults = {}
  defaults = mergeIf(isNil(animate['opacity']), defaults, { opacity: null })
  defaults = mergeIf(isNil(animate['backgroundColor']), defaults, { backgroundColor: null })

  // inline styles
  const inline = { ...useSpring(animate), ...defaults}

  // classes
  const shadow = isNil(element['shadow']) ? '' : element['shadow'] === 'on' ? 'shadow' : 'no-shadow'

  return (
    <animated.div
      className={joinSpace(['fill centering transform', shadow])}
      style={inline}
    >
      {props.children}
    </animated.div>
  )
}

// TEXT
function BlockText(props)
{
  const { element, assets } = props

  // assets
  const text = getAsset(assets.texts, element.id)
  const family = getAsset(assets.fonts, element['font'])
  const color = getAsset(assets.colors, element['color'])

  // animated
  let animate = { config: config.default }
  animate = mergeIf(color, animate, { color: `rgb(${color['color']})` })
  animate.from = { color: 'rgb(0,0,0)' }

  // defaults
  let defaults = {
    fontSize: !isNil(element['size']) ? element['size'] : '40px'
  }
  defaults = mergeIf(isNil(animate['color']), defaults, { color: null })
  defaults = mergeIf(family, defaults, { fontFamily: family['name'] })

  // inline styles
  const inline = { ...useSpring(animate), ...defaults}

  // classes
  const shadow = isNil(element['shadow']) ? '' : element['shadow'] === 'on' ? 'shadowt' : 'no-shadowt'

  // TODO : if missing asset, show a placeholder
  if (!text) return null

  return (
    <animated.div
      style={inline}
      className={joinSpace(['editor events', shadow])}
      dangerouslySetInnerHTML={{ __html: text['raw'] }}
    />
  )
}

// IMAGE
function BlockImage(props)
{
  const { element, assets, draggable } = props

  // assets
  const image = getAsset(assets.images, element.id)

  // classes
  const shadow = isNil(element['shadow']) ? '' : element['shadow'] === 'on' ? 'shadow' : 'no-shadow'

  // TODO : if missing asset, show a placeholder
  if (!image) return null

  return (
    <div
      className={joinSpace([shadow])}
    >
      <img className='block' alt='' draggable={draggable} src={image.url}/>
    </div>
  )
}
