import React from 'react'
import { useState } from 'react'

import { joinSpace } from './helpers.js'

// visual UI
// TODO : better reusability refactoring
// TODO : styled-components

export function Input(props)
{
  return (
    <div
      className={props.margin ? 'md-m-1-h md-m-h-w' : 'md-m-1-t'}
    >
      <Container shade>
        <input
          className='center'
          type='text'
          placeholder='...'
          value={props.value}
          onChange={props.callback}
        />
      </Container>
    </div>
  )
}

export function Toggle(props)
{
  const { children, callback, toggled, margin, disabled } = props

  const [pressed, setPressed] = useState(false)

  const press = () => setPressed(true)
  const release = () => setPressed(false)

  const toggle = () => {
    setPressed(false)
    if (callback && !disabled) callback()
  }

  const active = pressed ? false : toggled ? false : true

  return (
    <div
      onMouseDown={press}
      onMouseLeave={release}
      onMouseUp={toggle}
      onTouchStart={press}
      onTouchEnd={release}
      className={joinSpace([
        'md',
        margin ? 'md-m-1-h md-m-h-w' : 'md-m-1-t'
      ])}
    >
      <Container shade={active} disabled={disabled}>
        {children}
      </Container>
    </div>
  )
}

export function Container(props)
{
  const { children, shade, disabled } = props

  const back = disabled ? 'back-gray' : 'back-white'

  return (
    <div
    className={joinSpace([
      'no-select pointer hidden nowrap ellipsis pt-h md md-p-h-w radius inset anim lh',
      shade ? 'shad' : 'no-shad',
      back,
    ])}
    >
      {children}
    </div>
  )
}

export function Info(props)
{
  const { children } = props

  return (
    <p className='center md md-m-1-h md-m-h md-p-h-w back-black white radius inset lh'>{children}</p>
  )
}

export function Rule()
{
  return <p className='md md-m-1-h md-m-h md-p-h-w border-top lh'/>
}
