import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useMappedState } from 'redux-react-hook'
import { Helmet } from 'react-helmet-async'
import isHotkey from 'is-hotkey'

import { Scene } from './Scene'
import { Manager } from './Manager'
import { Routing } from './Routing'
import { joinSpace } from './helpers'

// client-side routing, resize events, ...
// TODO: routing
export function App(props)
{
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [editing, setEditing] = useState(false)

  const mapState = useCallback(state => ({
    nolibrary: state.library.loading,
    assets: state.library.assets,
  }), [])
  const { nolibrary, assets } = useMappedState(mapState)

  // resize events
  useEffect(() => {
    // TODO: normalize device pixel ratio (window.devicePixelRatio)
    const resize = () =>
    {
      const ww = window.innerWidth
      const wh = window.innerHeight
      // const ww = window.screen.width
      // const wh = window.screen.height
      // const ww = document.body.getBoundingClientRect().width
      // const wh = document.body.getBoundingClientRect().height
      setWidth(ww)
      setHeight(wh)

      // fluid typography: document body rounded font size for more consistent baseline grid
      // https://css-tricks.com/snippets/css/fluid-typography/
      // minFont + (maxFont - minFont) * ((100vw - minWidth) / (maxWidth - minWidth)))
      const fluidTypo = Math.floor(6+(12-6)*((ww-320)/(1920-320))) * 2
      document.body.style.fontSize = fluidTypo + 'px'
    }

    resize()
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  // keyboard events
  useEffect(() => {
    const handleKey = (e) =>
    {
      // TODO: only if logged in the admin ?
      if (isHotkey('shift+alt', e)) setEditing(!editing)
    }

    window.addEventListener('keydown', handleKey)
    return () => window.removeEventListener('keydown', handleKey)
  }, [editing])

  // create fonts link elements to pass to helmet
  const fonts = !nolibrary && assets.fonts.map((f, i) => React.createElement('link', { key: i, rel: 'stylesheet', href: f['src'] }))

  // editing style
  const back = editing ? 'cross' : ''

  // console.log(props)

  return (
    <>
      <Routing />
      <Helmet>
        {fonts}
      </Helmet>
      <div className={joinSpace(['fill relative hidden', back])}>
        <Scene width={width} height={height} editing={editing} />
        <Manager editing={editing} />
      </div>
    </>
  )
}
