import React from 'react'
import { useCallback, useState } from 'react'
import { useDispatch, useMappedState } from 'redux-react-hook'

import { Toggle, Info } from './UI'
import { Controls } from './Controls'
import { Tree } from './Tree'
import { projectSave } from './store'

// ui components logic
export function Manager(props)
{
  const { editing } = props

  const mapState = useCallback(state => ({
    noproject: state.project.loading,
    logged: state.project.logged,
  }), [])
  const { noproject, logged } = useMappedState(mapState)

  const dispatch = useDispatch()
  const dispatchAction = useCallback((action) => dispatch(action), [dispatch])

  // const [textLeft, setTextLeft] = useState(null)
  const [textRight, setTextRight] = useState(null)

  // load ui when project is ready
  if (noproject) return null

  // actions callbacks
  const save = () => dispatchAction(projectSave())

  // toggle ui
  // TODO: spring animation
  const display = editing ? '' : 'none'

  const style = {
    top: '0',
    padding: '50vh 0',
    // overflow: 'auto',
  }

  return (
    <>
      <div
        className='absolute'
        style={{display: display, left: 0}}
      >
        <div className='scroll-y fill-h absolute anim' style={style} >
          <Tree id='root' name='∆' breadcrumb={['root']} />
        </div>
      </div>

      <div
        className='absolute'
        style={{display: display, left: '0', bottom: '0'}}
      >
        <div className='row row-left'>
          {logged &&
            <Toggle margin callback={save}>
              <p className='center'>Save</p>
            </Toggle>
          }
        </div>
      </div>

      <div
        className='absolute'
        style={{display: display, left: '0', top: '0'}}
      >
        <div className='row row-left'>
          <Info>MagicMove 1.0</Info>
        </div>
      </div>

      <div
        className='absolute'
        style={{display: display, right: '0'}}
      >
        <Controls notify={setTextRight}/>
      </div>

      <div
        className='absolute'
        style={{display: display, right: '0', top: '0'}}
      >
        <div className='row row-right'>
          {textRight && <Info>{textRight}</Info>}
        </div>
      </div>
    </>
  )
}
