import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/es/map'
import 'core-js/es/set'
import 'raf/polyfill'

import React from 'react'
import { render } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { StoreContext } from 'redux-react-hook'

import { App } from './App'
import { store } from './store'
import './index.css'

render(
  <StoreContext.Provider value={store}>
    <HelmetProvider>
      <App path='/'/>
    </HelmetProvider>
  </StoreContext.Provider>,
  document.getElementById('root')
)

console.log('%cDear Developer, ','font-size: 21px; color: #151515; font-family: "Times"; font-weight: 600;')
