import { useRef, useEffect } from 'react'
import { v4 } from 'uuid'
import isNil from 'lodash/isNil'
import filter from 'lodash/filter'
import find from 'lodash/find'
import partial from 'lodash/partial'
import every from 'lodash/every'
import has from 'lodash/has'
import isMatch from 'lodash/isMatch'

// uuid
// https://en.wikipedia.org/wiki/Universally_unique_identifier
export function getId()
{
  return v4()
}

// https://github.com/lodash/lodash/pull/576#issuecomment-45225777
export function hasKeys(o, k)
{
  return every(k, partial(has, o))
}

// search arrays by key/values
export function filterKV(state, kvs, equals = true)
{
  return filter(state, o => isMatch(o, kvs) === equals)
}
export function findKV(state, kvs)
{
  return find(state, kvs)
}

// delete keys from input array
export function deleteMany(state, keys)
{
  // tree = Object.assign({}, state)
  state = { ...state }
  keys.forEach(key => delete state[key])
  return state
  // return omit(state, keys)
}

// object with or without key (if value is null)
// if passed property value is null: remove it by spread syntax omission
// https://stackoverflow.com/a/34710102/7662622
// https://github.com/babel/babel/issues/4196
export function withorwithout(obj, key, val)
{
  let mod = { ...obj, [key]: val }
  if (isNil(val)) {
    const { [key]: omit, ...remains } = obj
    mod = remains
  }
  return mod
}

// returns merged object if [cond], or same
export function mergeIf(cond, obj, props)
{
  return cond ? { ...obj, ...props } : obj
}

// returns asset if available, or false
export function getAsset(assets, id)
{
  if (isNil(id)) return false
  let find = assets.filter(f => f.id === id)
  if (find.length) return find[0]
  return false
}

// first char uppercase
export function firstcap(s)
{
  return s.charAt(0).toUpperCase()
}
export function firstcapfull(s)
{
  return firstcap(s) + s.slice(1)
}

// joining with spaces utility (can be used for classnames)
export function joinSpace(c)
{
  return c.filter(Boolean).join(' ')
}

// https://stackoverflow.com/a/5306832/7662622
export function arrayMove(arr, previousIndex, newIndex)
{
  const array = arr.slice(0)

  if (newIndex >= array.length) {
    let k = newIndex - array.length
    while (k-- + 1) {
      array.push(undefined)
    }
  }

  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0])

  return array
}

export function clamp(number, min, max)
{
  return Math.min(Math.max(number, min), max)
}
// export function clamp(value, min, max)
// {
//   return min < max
//     ? (value < min ? min : value > max ? max : value)
//     : (value < max ? max : value > min ? min : value)
// }

export function mapNumber(value, min1, max1, min2, max2)
{
  return (value - min1) * (max2 - min2) / (max1 - min1) + min1
}

// previous props hook
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious(value)
{
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

// // https://redux.js.org/recipes/structuring-reducers/refactoring-reducers-example
// // https://gist.github.com/gorangajic/e902c2ee994260b3348d
// export function objectUpdate(obj, vals)
// {
//   // return Object.assign({}, obj, vals)
//   return {...obj, ...vals}
// }
// export function arrayUpdate(arr, index, cb)
// {
//   return [
//     ...arr.slice(0, index),
//     cb(arr[index]),
//     ...arr.slice(index + 1)
//   ]
// }

// interval hook
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// export function useInterval(callback, delay)
// {
//   const trigger = useRef()
//
//   useEffect(() => {
//     trigger.current = callback
//   })
//
//   useEffect(() => {
//     const tick = () => trigger.current()
//     let id = setInterval(tick, delay)
//     return () => clearInterval(id)
//   }, [delay])
// }

// https://stackoverflow.com/a/51082563/7662622
// function useTraceUpdate(props) {
//   const prev = useRef(props)
//   useEffect(() => {
//     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//       if (prev.current[k] !== v) {
//         ps[k] = [prev.current[k], v]
//       }
//       return ps
//     }, {});
//     if (Object.keys(changedProps).length > 0) {
//       console.log('Changed props:', changedProps)
//     }
//     prev.current = props
//   })
// }
