import React from 'react'
import { useCallback } from 'react'
import { useMappedState } from 'redux-react-hook'

import { Master } from './Elements'
import { ancestorMaster } from './store'
import { joinSpace } from './helpers'

// scene grid units
// export const grid = { width: 1920, height: 1080, columns: 48, rows: 27, offset: 40 }
export const grid = { width: 1080, height: 1920, columns: 27, rows: 48, offset: 40 }
// map point values to our domain
export const gridX = (v) => Math.floor(v * grid.columns)
export const gridY = (v) => Math.floor(v * grid.rows)

// render scene (ratio and size, letterboxing), ...
// TODO : customizable scene format, scene rotation...
// TODO : autoprefixer for transforms...
// TODO : block to 16:9 ?
// TODO: how to make the grid fit screen ? > scene fixed size then scale ?
// TODO: fix <1 scale bug
export function Scene(props)
{
  const { width, height, editing } = props

  const mapState = useCallback(state => ({
    tree: state.tree,
    selected: state.global.selected,
  }), [])
  const { tree, selected } = useMappedState(mapState)

  // get the master to render
  // render first master ancestor according to selection > allows to edit a master independantly from the rest
  // if selected master is a child (not root), don't include as ancestor to see it with parent component
  // in view mode, selected is root
  // TODO : avoid selector by directly accessing it from the store helper function
  // TODO : reconcile with current master slide (edit vs. view) ?
  // TODO : do this further down in master ?
  // TODO : use breadcrumb for instance prop ?
  const includes = selected === 'root'
  const id = ancestorMaster(tree, selected, includes)
  const master = tree[id]

  // // ratio
  // const portrait = width / height < 1.0
  //
  // // scene rotation
  // const rotation = portrait ? 'rotate(-90deg)' : ''

  // scene scale
  // https://css-tricks.com/scaled-proportional-blocks-with-css-and-javascript/
  const sceneWidth = grid.width
  const sceneHeight = grid.height
  const scaleX = width / sceneWidth
  const scaleY = height / sceneHeight
  const sceneScale = Math.min(scaleX, scaleY)

  // const sceneWidth = grid.width
  // const sceneHeight = grid.height
  // const scaleX = portrait ? width / sceneHeight : width / sceneWidth
  // const scaleY = portrait ? height / sceneWidth : height / sceneHeight
  // const sceneScale = Math.min(scaleX, scaleY)

  // scene letterboxing
  const style = {
    width: sceneWidth,
    minWidth: sceneWidth,
    height: sceneHeight,
    minHeight: sceneHeight,
    transform: `scale(${sceneScale})`,
    WebkitTransform: `scale(${sceneScale})`,
  }

  // const sceneStyle = {
  //   width: sceneWidth,
  //   height: sceneHeight,
  //   transform: joinSpace([`scale(${sceneScale})`, rotation]),
  // }

  const blueprint = editing ? 'grid bor no-select' : ''

  return (
    <div className='absolute corner-tl fill centering'>
      <div className={joinSpace(['relative centering', blueprint])} style={style}>
        <Master target={master} parent={id} editing={editing} selected={selected}/>
      </div>
    </div>
  )
}
